import { Column } from "react-table";
import { IManual } from "types/machine";
import Combined from "./cells/combinedCells";

export const createTinyColumns = (handleDelete: any): Column<IManual>[] =>
  [
    {
      id: "combined",
      Header: "",
      accessor: ({
        id,
        machine_category,
        is_shared,
        description,
        machine_subcategory,
        machine_manufacturer,
        machine_product_lines,
        machine_model_types,
        machine,
        projects,
        companyName,
        projectName,
        isMachine,
        machineManufacturerName,
        machineProductLineName,
        machineModelTypeName,
        machineName,
        attachment,
        link,
      }) => {
        return {
          id,
          machine_category,
          is_shared,
          description,
          machine_subcategory,
          machine_manufacturer,
          machine_product_lines,
          machine_model_types,
          machine,
          projects,
          isMachine,
          companyName,
          projectName,
          machineManufacturerName,
          machineProductLineName,
          machineModelTypeName,
          machineName,
          attachment,
          link,
          handleDelete,
        };
      },
      Cell: Combined,
      custom: {
        hidePriority: 0,
        margin: "0 0 0 1rem",
        customBorderBottom: ({ row }: any) =>
          row.original.isMachine && "5px solid #9f9f9f",
      },
    },
  ] as Column<IManual>[] | any[];
