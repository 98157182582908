import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function getMachineProductLines({
  manufacturer,
  subcategory,
}: {
  manufacturer?: number | number[] | null;
  subcategory?: number | null;
}) {
  const url = endpoints.machines.machineProductLines;
  try {
    const { data } = await axiosNeuron.get(url, {
      params: {
        manufacturer: manufacturer ? manufacturer : undefined,
        subcategory: subcategory ? subcategory : undefined,
      },
    });

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
