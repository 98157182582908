import { Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import TooltipComponent from "components/tooltip";
import { useState } from "react";

const Note = ({
  cell: {
    value: { description },
  },
}: any) => {
  const [openNote, setOpenNote] = useState(false);

  return (
    <Box>
      <TooltipComponent
        title={
          <span>
            {description ? description : "manual.detail.table.noNote"}
          </span>
        }
        onOpen={() => setOpenNote(!openNote)}
      >
        <InfoIcon />
      </TooltipComponent>
    </Box>
  );
};

export default Note;
