import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import { useAsyncReducer } from "store/selectTemplate";
import getMachineCategories from "api/handlers/machine/getMachineCategories";
import { ICategoryList, ISubcategoryList } from "types/machine";
import SelectGroupSearch, {
  TOptionsGroup,
} from "components/select/selectGroupSearch";
import MultiSelectGroupSearch from "components/eventSounds/components/filters/multiSelectGroupSearch";
import getMachineSubcategories from "api/handlers/machine/getMachineSubcategories";
import actions from "store/manuals/actions";

const CategoriesAndSubcategoriesFilter = ({ modalSelect = false }: any) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const [options, setOptions] = useState<TOptionsGroup>([]);
  const [searchPhrase, setSearchPhrase] = useState("");

  // To be updated
  const { machineSubcategories: machineSubcategoriesValues, modalSubcategory } =
    useSelector((state: AppState) => state.manuals);

  const { data: categoriesRes } = useAsyncReducer(
    "categoriesSelect",
    getMachineCategories,
    {}
  );

  const { data: machineSubcategories, loading } = useAsyncReducer(
    "subCategoriesSelect",
    getMachineSubcategories,
    {}
  );

  const mergeCategories = (
    categories: ICategoryList[],
    subCategories: ISubcategoryList[]
  ) => {
    let merged: TOptionsGroup = [];
    categories?.forEach((category) => {
      const targetSubCategories = subCategories?.filter(
        (sub) => sub.category === category.id
      );
      if (targetSubCategories.length) {
        merged.push({
          group: category.name,
          items: targetSubCategories.map((sub) => ({
            name: sub.name,
            id: sub.id,
          })),
        });
      }
    });
    return merged;
  };

  const searchCatAndSubCat: (
    value: string,
    categories: ICategoryList[],
    subCategories: ISubcategoryList[]
  ) => [ICategoryList[], ISubcategoryList[]] = (
    value: string,
    categories: ICategoryList[],
    subCategories: ISubcategoryList[]
  ) => {
    if (value === "") return [categories, subCategories];

    const targetCategoryId = categories
      .filter((category) => {
        return value
          ? category.name.toLowerCase().includes(value.toLocaleLowerCase())
          : category;
      })
      .map((company) => company.id);

    const targetSubCategories = subCategories?.filter((subCategory) => {
      const searchResult = subCategory.name
        .toLowerCase()
        .includes(value.toLowerCase());
      if (searchResult || targetCategoryId.indexOf(subCategory.category) >= 0) {
        targetCategoryId.push(subCategory.category);
      }
      return (
        searchResult ||
        targetCategoryId.indexOf(subCategory.category || -1) >= 0
      );
    });

    return [
      categories?.filter(
        (category) => targetCategoryId.indexOf(category.id) >= 0
      ),
      targetSubCategories,
    ];
  };

  useEffect(() => {
    if (
      categoriesRes?.results &&
      categoriesRes?.results.length &&
      machineSubcategories?.results &&
      machineSubcategories?.results.length
    ) {
      const [cat, subCat] = searchCatAndSubCat(
        searchPhrase,
        categoriesRes.results,
        machineSubcategories?.results
      );
      setOptions(mergeCategories(cat, subCat));
    }
  }, [categoriesRes?.results, searchPhrase, machineSubcategories?.results]);

  const onChange = useCallback(
    (values: number[] | string[]) => {
      dispatch(actions.setMachineSubcategories(values.length ? values : null));
    },
    [dispatch]
  );

  const onChangeModalSelect = useCallback(
    (event: any) => {
      dispatch(actions.setModalSubcategory(event.target.value));
    },
    [dispatch]
  );

  return (
    <>
      {modalSelect ? (
        <SelectGroupSearch
          id="machineSubcategoryId"
          label={t("manualModal.manualHierarchy.machineSubcategory.label")}
          name="machineSubcategoryId"
          options={
            !loading
              ? options
              : [
                  {
                    group: t("loading"),
                    items: [{ name: "", id: 0 }],
                  },
                ]
          }
          required
          value={modalSubcategory}
          onChange={onChangeModalSelect}
          search={searchPhrase}
          onSearch={setSearchPhrase}
          searchPlaceholder={`${t("manualModal.manualHierarchy.machineSubcategory.searchPlaceholder")}`}
        />
      ) : (
        <MultiSelectGroupSearch
          id="machine_category_and_subcategory"
          name="machineCatAndSubcategory"
          options={options}
          selectedValues={machineSubcategoriesValues || []}
          onChange={onChange}
          search={searchPhrase}
          label={t("manual.filter.machineCatAndSubCategory")}
          placeholder={t("search.searchCatAndSubCategory")}
          setSearchPhrase={setSearchPhrase}
          loading={loading}
        />
      )}
    </>
  );
};

export default CategoriesAndSubcategoriesFilter;
