import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

type Props = {
  breakLine?: boolean;
};

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "inline-block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      [theme.breakpoints.up("sm")]: {
        marginBottom: 0,
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    labelTop: {
      width: "inherit",
      whiteSpace: (props: Props) => (props?.breakLine ? "normal" : "nowrap"),
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  };
});

type TRegularProps = {
  cell: {
    value: { label: string; classN?: string; breakLine?: boolean };
  };
};

const Regular = (props: TRegularProps) => {
  const {
    cell: {
      value: { label, classN, breakLine },
    },
  } = props;
  const classes = useStyles({ breakLine });
  return (
    <span className={classes.root}>
      <span className={clsx(classes.labelTop, classN || "")}>{label}</span>
    </span>
  );
};

export default Regular;
