import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import { useAsyncReducer } from "store/selectTemplate";
import actions from "store/manuals/actions";
import SelectField from "components/selectField";
import getMachines from "api/handlers/machine/getMachines";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  filter: {
    width: "100%",

    "& .MuiSelect-select": {
      paddingTop: "11.5px",
      paddingBottom: "11.5px",
    },
  },
}));

const Machine = ({ modalSelect = false, disabled = false }: any) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const { machineSubcategories, modalSubcategory, modalMachine, machines } =
    useSelector((state: AppState) => state.manuals);

  const { data, loading } = useAsyncReducer("machineSelect", getMachines, {
    subcategory: modalSelect
      ? modalSubcategory
        ? modalSubcategory
        : undefined
      : machineSubcategories && machineSubcategories.length
        ? machineSubcategories
        : undefined,
  });

  const machineRes = useMemo(() => {
    let options: any = [];
    if (data) {
      options = data?.results.map((item: any) => ({
        text: item.name,
        value: item.id,
      }));
    }
    return modalSelect
      ? [
          {
            text: t("manual.machineHierarchy.unassigned"),
            value: "",
          },
          ...options,
        ]
      : options;
  }, [data]);

  const onChange = useCallback(
    (values: number[] | string[]) => {
      dispatch(actions.setMachines(values.length ? values : null));
    },
    [dispatch]
  );
  const onChangeModalSelect = useCallback(
    (event: any) => {
      dispatch(actions.setModalMachine(event.target.value));
    },
    [dispatch]
  );

  const ss = machineRes ? machineRes : [];
  return (
    <>
      {modalSelect ? (
        <FormControl
          disabled={disabled}
          variant="outlined"
          className={classes.filter}
        >
          <InputLabel id="testMachineManufacturerSelect">
            {t("manualModal.manualHierarchy.machine.label")}
          </InputLabel>
          <Select
            id="testMachineManufacturerSelect"
            labelId="testMachineManufacturerSelect"
            label={t("manualModal.manualHierarchy.machine.label")}
            value={modalMachine}
            onChange={onChangeModalSelect}
            placeholder={t(
              "manualModal.manualHierarchy.machine.searchPlaceholder"
            )}
          >
            {ss.map((option: any) => {
              return (
                <MenuItem value={option.value} key={option.value}>
                  {option.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <SelectField
          searchPlaceholder={t("search.searchMachine")}
          initialValue={
            modalSelect ? modalMachine || undefined : machines || undefined
          }
          loading={!data || loading}
          disabled={disabled}
          name="testMachineSelect"
          label={t("manual.filter.machine")}
          id="testMachineSelect"
          options={machineRes}
          onChangeSubmit={onChange}
          data-testid="select-field-machine"
        />
      )}
    </>
  );
};

export default Machine;
