import React, { useLayoutEffect, useRef, useEffect } from "react";
import { UseTableInstanceProps } from "react-table";
import { makeStyles } from "@mui/styles";
import useViewportWidth from "shared/useViewportWidth";
import {
  showAllHiddenColumns,
  someColumnNeedsToBeHided,
  getColumnToHide,
} from "./responsiveHelpers";
import { RowRefs } from "./types";
import clsx from "clsx";

interface IProps {
  id?: number;
  rowHeight?: number | string;
  rowWidth?: number | string;
  onClick?: (id?: number) => void;
  height?: string | number;
}

const useStyles = makeStyles((theme: any) => {
  return {
    row: {
      "&:hover": {
        cursor: ({ onClick }: IProps) => (!!onClick ? "pointer" : "initial"),
        background: ({ onClick }: IProps) =>
          !!onClick
            ? theme.custom.palette.primary[4]
            : theme.custom.palette.surface,
      },
      "&:hover td": {
        borderColor: ({ onClick }: IProps) =>
          !!onClick
            ? theme.custom.palette.primary[16]
            : theme.custom.palette.border,
      },
    },
    td: {
      padding: 0,
      height: ({ rowHeight }: IProps) =>
        rowHeight ? rowHeight : theme.spacing(9),
      borderTop: `${theme.spacing(0.125)} solid ${theme.custom.palette.border}`,
      borderBottom: `${theme.spacing(0.125)} solid ${
        theme.custom.palette.border
      }`,
      "&:first-child": {
        borderTopLeftRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
        borderLeft: `${theme.spacing(0.125)} solid ${
          theme.custom.palette.border
        }`,
      },
      "&:last-child": {
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        borderRight: `${theme.spacing(0.125)} solid ${
          theme.custom.palette.border
        }`,
      },
    },
    tdVerticalAlignTop: {
      verticalAlign: "top",
    },
    div: {
      display: "block",
      height: ({ height }: IProps) => height,
      [theme.breakpoints.up("md")]: {
        display: "inline-block",
        width: ({ rowWidth }: IProps) => rowWidth || "auto",
      },
    },
    selected: {
      background: theme.custom.palette.primary[4],
    },
  };
});

type TRowProps = {
  id: number;
  rowHeight?: number | string;
  project?: number | null;
  selected?: number[];
  isGrayedOut?: boolean;
  rowStyle?: Object;
  height?: string | number;
};

interface Props<T extends object>
  extends Pick<UseTableInstanceProps<T>, "rows" | "prepareRow" | "allColumns"> {
  onClick?: (id?: number, rest?: any) => void;
  selected?: number[];
  height?: string | number;
}

function Row<T extends TRowProps>(props: Props<T>) {
  const { rows, selected, prepareRow, allColumns, onClick } = props;
  const classes = useStyles(props);
  const rowRefsContainer: RowRefs[] = rows.map(() => ({
    tdRefs: {},
    divRefs: {},
  }));
  const viewportWidth = useViewportWidth();
  const tableWidth = viewportWidth.width;
  const resolvingStateForWidth = useRef<{
    [key: number]: { showed: boolean; done: boolean };
  }>({});
  const prevTableWidth = useRef(tableWidth);

  useEffect(() => {
    prevTableWidth.current = tableWidth;
  }, [tableWidth]);

  useLayoutEffect(() => {
    if (prevTableWidth.current !== tableWidth) {
      resolvingStateForWidth.current = {};
    }
    if (!resolvingStateForWidth.current[tableWidth]) {
      resolvingStateForWidth.current[tableWidth] = {
        showed: false,
        done: false,
      };
    }
    const currentResolvingState = resolvingStateForWidth.current[tableWidth];
    if (!currentResolvingState.showed) {
      showAllHiddenColumns(allColumns);
    }
    currentResolvingState.showed = true;

    let someColumnNeedsToBeHidedValue = someColumnNeedsToBeHided(
      rows,
      allColumns,
      rowRefsContainer
    );

    if (someColumnNeedsToBeHidedValue) {
      const columnToHide = getColumnToHide(allColumns);
      if (columnToHide) {
        columnToHide.toggleHidden();
      }
    }

    if (!someColumnNeedsToBeHidedValue) {
      currentResolvingState.done = true;
    }
  }, [allColumns, rowRefsContainer, rows, tableWidth]);

  const handleRowClick = (id?: number, rest?: any) => {
    if (onClick) {
      onClick(id, rest);
    }
  };

  return (
    <>
      {rows.map((row, rowIndex) => {
        prepareRow(row);
        return (
          <tr
            className={
              classes.row +
              " " +
              (selected && selected.indexOf(row.original.id) !== -1
                ? classes.selected
                : "")
            }
            {...row.getRowProps()}
            style={{
              opacity: row.original.isGrayedOut ? 0.4 : 1,
              ...(row.original.rowStyle || {}),
            }}
            onClick={() => handleRowClick(row.original.id, row.original)}
          >
            {row.cells.map((cell: any) => {
              const borderBottomStyle =
                cell.column.custom?.customBorderBottom?.({ row: cell.row });

              return (
                <td
                  {...cell.getCellProps()}
                  className={clsx(
                    classes.td,
                    cell.column.custom.verticalAlignTop &&
                      classes.tdVerticalAlignTop
                  )}
                  ref={(ref: any) => {
                    rowRefsContainer[rowIndex].tdRefs[cell.column.id] = ref;
                  }}
                  style={{
                    width: cell.column.custom?.width,
                    minWidth: cell.column.custom?.minWidth,
                    textAlign: cell.column.custom.alignRight
                      ? "right"
                      : "initial",
                    borderTop: borderBottomStyle,
                  }}
                >
                  <div
                    className={classes.div}
                    ref={(ref: any) => {
                      rowRefsContainer[rowIndex].divRefs[cell.column.id] = ref;
                    }}
                    style={{
                      margin: cell.column.custom.margin
                        ? cell.column.custom.margin
                        : "initial",
                      width: cell.column.custom?.contentWidth
                        ? cell.column.custom?.contentWidth
                        : cell.column.custom?.alignRight
                          ? "initial"
                          : undefined,
                    }}
                  >
                    {cell.render("Cell")}
                  </div>
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
}

export default Row;
