import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Filter from "./filter";
import HorizontalLine from "components/horizontalLine";
import { useDispatch } from "react-redux";
import actions, { fetchManuals } from "store/manuals/actions";
import { useSelector } from "react-redux";
import { AppState } from "store";
import Spinner from "components/spinner";
import Details from "./detail";
import { useStyles } from "./styles";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { SortingRule } from "react-table";

const MachineManuals: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch: any = useDispatch();
  const { manuals, isFetching, sort, filters } = useSelector(
    (state: AppState) => state.manuals
  );
  const classes = useStyles();

  const [categoryId, setCategoryId] = useState(null);
  const [subcategoryId, setSubcategoryId] = useState(null);

  const groupedByCategories = useMemo(() => {
    return manuals
      ? manuals.results.reduce((acc: any, item: any) => {
          const category = item.machine_category;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(item);
          return acc;
        }, {})
      : [];
  }, [manuals]);

  const groupedData = Object.values(groupedByCategories);

  const initialState = useMemo(
    () => ({
      sortBy: sort,
    }),
    [sort]
  );

  const onPageSort = useCallback(
    (sortBy: SortingRule<number>[]) => {
      dispatch(actions.setSort(sortBy));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchManuals({ orderBy: sort, filters }));
  }, [dispatch, sort, filters]);

  return (
    <>
      <Filter />
      <HorizontalLine />
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <TableContainer className={classes.container}>
            <Table className={classes.table}>
              <TableHead></TableHead>
              {groupedData.map((group: any) => {
                const groupedBySubcat = group.reduce((acc: any, item: any) => {
                  const subcat = item.machine_subcategory;
                  if (!acc[subcat]) {
                    acc[subcat] = [];
                  }
                  acc[subcat].push(item);
                  return acc;
                }, {});

                const modifedSubcat = groupedBySubcat
                  ? Object.values(groupedBySubcat)
                  : [];
                return (
                  <React.Fragment key={group[0].machine_category}>
                    <thead className={classes.break} />
                    <TableBody
                      key={group[0].machine_category}
                      className={classes.tableContent}
                    >
                      <TableRow className={classes.tr}>
                        <TableCell
                          className={`${classes.td} ${classes.tdCollapsed}`}
                          style={{ width: "24px" }}
                        >
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              setCategoryId((prev) =>
                                !prev
                                  ? group[0].machine_category
                                  : prev !== group[0].machine_category
                                    ? group[0].machine_category
                                    : null
                              );
                              setSubcategoryId(null);
                            }}
                          >
                            {categoryId === group[0].machine_category ? (
                              <KeyboardArrowUp />
                            ) : (
                              <KeyboardArrowDown />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          colSpan={8}
                          className={`${classes.td} ${classes.tdCollapsed}`}
                        >
                          {group[0].machineCategoryName}
                        </TableCell>
                      </TableRow>
                      {categoryId === group[0].machine_category ? (
                        modifedSubcat.map((item: any) => {
                          return (
                            <React.Fragment key={item[0].machine_subcategory}>
                              <TableRow
                                key={item[0].machine_subcategory}
                                className={classes.tr}
                              >
                                <TableCell
                                  className={`${classes.td} ${classes.tdCollapsed}`}
                                ></TableCell>
                                <TableCell
                                  colSpan={1}
                                  className={`${classes.td} ${classes.tdCollapsed}`}
                                  style={{ width: "24px" }}
                                >
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => {
                                      setSubcategoryId((prev) =>
                                        !prev
                                          ? item[0].machine_subcategory
                                          : prev !== item[0].machine_subcategory
                                            ? item[0].machine_subcategory
                                            : null
                                      );
                                    }}
                                  >
                                    {subcategoryId ===
                                    item[0].machine_subcategory ? (
                                      <KeyboardArrowUp />
                                    ) : (
                                      <KeyboardArrowDown />
                                    )}
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  colSpan={6}
                                  className={`${classes.td} ${classes.tdCollapsed}`}
                                >
                                  {item[0].machineSubcategoryName}
                                </TableCell>
                              </TableRow>
                              {subcategoryId === item[0].machine_subcategory ? (
                                <TableRow className={classes.tr}>
                                  <TableCell
                                    colSpan={9}
                                    style={{ padding: 0 }}
                                    className={`${classes.td} ${classes.tdCollapsed}`}
                                  >
                                    <Details
                                      data={item ? item : []}
                                      loading={isFetching}
                                      initialState={initialState}
                                      onPageSort={onPageSort}
                                    />
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </TableBody>
                  </React.Fragment>
                );
              })}
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default MachineManuals;
