import { Box, Button, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IMuiTheme from "types/styles";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useSelector } from "react-redux";
import { AppState } from "store";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles((theme: IMuiTheme) => ({
  button: {
    color: theme.custom.palette.primary[100],
    cursor: "pointer",
    display: "block",
    "&:hover": {
      color: theme.custom.palette.data,
    },
  },
  buttonContainer: {
    backgroundColor: theme.custom.palette.border,
    "&:hover": {
      backgroundColor: "#d5d5d5",
    },
  },
  ellipsisBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: "5px",
  },
}));

const DownloadAndDelete = ({
  cell: {
    value: { link, handleDelete },
  },
}: any) => {
  const classes = useStyles();
  const user = useSelector((state: AppState) => state.auth.user);
  const isReadOnly = user.role === "customer";
  return (
    <Box className={classes.ellipsisBox}>
      <Link href={link} target="_blank">
        <GetAppIcon className={classes.button} />
      </Link>
      <Box mr={2.5} style={isReadOnly ? { cursor: "not-allowed" } : {}}>
        <Button
          disabled={isReadOnly}
          onClick={handleDelete}
          className={classes.buttonContainer}
          variant="contained"
        >
          <DeleteIcon className={classes.button} />
        </Button>
      </Box>
    </Box>
  );
};

export default DownloadAndDelete;
