import { CellProps } from "react-table";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ActionsButton from "./actionsButton";
import { useTranslation } from "react-i18next";
import DownloadAndDelete from "./downloadAndDelete";
import Note from "./note";
import CompanyAndProject from "./companyAndProject";
import { Typography } from "@mui/material";
import Regular from "./regular";
import File from "./file";
import RegularList from "./regularList";

const Combined = <T extends Object>({
  cell: {
    value: {
      id,
      machine_category,
      is_shared,
      description,
      machine_subcategory,
      machine_manufacturer,
      machine_product_lines,
      machine_model_types,
      machine,
      projects,
      companyName,
      projectName,
      isMachine,
      machineManufacturerName,
      machineProductLineName,
      machineModelTypeName,
      machineName,
      attachment,
      link,
      handleDelete,
    },
  },
}: CellProps<T>) => {
  const { t } = useTranslation();

  return (
    <Box pt={2} pb={2}>
      <Grid container justifyContent="space-between" gap={1} mb={1}>
        <Grid item>
          <Box>
            <Typography>{t("manual.detail.companyAndProject")}:</Typography>
            <CompanyAndProject
              cell={{
                value: { is_shared, companyName, projectName, isMachine },
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Note
                cell={{
                  value: { description },
                }}
              />
            </Grid>
            <Grid item>
              <DownloadAndDelete
                cell={{
                  value: { link, handleDelete: () => handleDelete(id) },
                }}
              />
            </Grid>
            <Grid item>
              <ActionsButton
                cell={{
                  value: {
                    id,
                    machine_category,
                    is_shared,
                    description,
                    machine_subcategory,
                    machine_manufacturer,
                    machine_product_lines,
                    machine_model_types,
                    machine,
                    projects,
                    isMachine,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box mb={1}>
        <Typography>
          {t("manual.detail.manufacturer")}:{" "}
          {machineManufacturerName ? <></> : "-"}
        </Typography>
        {machineManufacturerName ? (
          <Regular
            cell={{
              value: {
                label: machineManufacturerName,
              },
            }}
          />
        ) : (
          <></>
        )}
      </Box>
      <Box mb={1}>
        <Typography>
          {t("manual.detail.machineProductLine")}:{" "}
          {machineProductLineName ? <></> : "-"}
        </Typography>
        {machineProductLineName ? (
          <RegularList
            cell={{
              value: {
                label: machineProductLineName,
              },
            }}
          />
        ) : (
          <></>
        )}
      </Box>
      <Box mb={1}>
        <Typography>
          {t("manual.detail.machineModelType")}:{" "}
          {machineModelTypeName ? <></> : "-"}
        </Typography>
        {machineModelTypeName ? (
          <RegularList
            cell={{
              value: {
                label: machineModelTypeName,
              },
            }}
          />
        ) : (
          <></>
        )}
      </Box>
      <Box mb={1}>
        <Typography>
          {t("manual.detail.machine")}: {machineName ? <></> : "-"}
        </Typography>
        {machineName ? (
          <Regular
            cell={{
              value: {
                label: machineName,
              },
            }}
          />
        ) : (
          <></>
        )}
      </Box>
      <Box>
        <Typography>{t("manual.detail.file")}:</Typography>
        <File
          cell={{
            value: {
              attachment,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Combined;
