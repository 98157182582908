import { Box, Button, Grid, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SingleActionModal from "components/singleActionModal";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Heading3 from "components/typography/heading/heading3";
import Paragraph from "components/typography/paragraph";
import { enqueueSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import { Launch } from "@mui/icons-material";
import { EModalTypes } from "store/manuals/types";
import CategoriesAndSubcategoriesFilter from "./filters/categoriesAndSubcategories";
import CompaniesAndProjectsFilter from "./filters/companiesAndProjects";
import Checkbox from "components/checkbox";
import { useSelector } from "react-redux";
import { AppState } from "store";
import actions, { createManual, updateManual } from "store/manuals/actions";
import MachineManufacturer from "./filters/machineManufacturer";
import MachineProductLine from "./filters/machineProductLine";
import MachineModelType from "./filters/machineModelType";
import Machine from "./filters/machine";

const useStyles = makeStyles((theme: any) => ({
  dropContainer: {
    position: "relative",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  modal: {
    [theme.breakpoints.up("sm")]: {
      width: "90%!important",
      height: "90%!important",
    },
  },

  input: {
    fontSize: theme.custom.typography.fontSize["14"],
    letterSpacing: theme.custom.typography.letterSpacing["medium"],
    width: "100%",
  },
}));

interface IProps {
  open: boolean;
  closeModal: () => void;
  modelType: EModalTypes;
  id?: number;
}

const CreateManuals = ({ open, closeModal, modelType, id }: IProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const classes = useStyles();
  const {
    isShared,
    isSpecificMachine,
    modalDescription,
    modalSubcategory,
    modalProjects,
    modalManufacturer,
    modalProductLine,
    modalModelType,
    modalMachine,
  } = useSelector((state: AppState) => state.manuals);

  const [attachments, setAttachments] = useState<any>([]);

  const onDragCapture = useCallback(
    async (e: any) => {
      e.stopPropagation();
      e.preventDefault();

      const files: FileList | null = e.target.files;

      if (!files || files.length === 0) {
        return;
      }

      const newAttachments: any = [];
      const currentAttachmentNames = attachments.map(
        (attachment: any) => attachment.name
      );

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const name = file.name;

        // Check if the file already exists
        if (currentAttachmentNames.includes(name)) {
          enqueueSnackbar(
            t("machine.detail.uploadManualAttachment.duplicateFile")
          );
          continue; // Skip to the next file
        }

        if (file.size > 10485760) {
          enqueueSnackbar(t("machine.detail.uploadManualAttachment.tooLarge"));
          continue; // Skip to the next file
        }

        try {
          const data = await new Promise<string>((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = (loadEvent: any) => {
              const target = loadEvent!.target!;
              const result = target.result!.toString().split(",")[1];
              resolve(result);
            };

            fileReader.onerror = (error) => {
              reject(error);
            };

            fileReader.readAsDataURL(file);
          });

          newAttachments.push({
            name,
            data,
            id: uuidv4(),
          });
        } catch (error) {
          enqueueSnackbar(
            t("machine.detail.uploadManualAttachment.fileReadError")
          );
        }
      }

      e.target.value = null; // Clear the input

      setAttachments((prevAttachments: any) => [
        ...prevAttachments,
        ...newAttachments,
      ]);
    },
    [enqueueSnackbar, setAttachments, t, attachments]
  );

  const onCancel = useCallback(() => {
    dispatch(actions.setModalSubcategory(null));
    dispatch(actions.setIsShared(true));
    dispatch(actions.setModalProjects(null));
    dispatch(actions.setModalManufacturer(null));
    dispatch(actions.setModalProductLine(null));
    dispatch(actions.setModalModelType(null));
    dispatch(actions.setIsSpecificMachine(false));
    dispatch(actions.setModalMachine(null));
    dispatch(actions.setModalDescription(null));

    setAttachments([]);
    closeModal();
  }, [dispatch]);

  const onFinish = useCallback(() => {
    onCancel();
    if (modelType === EModalTypes.create) {
      attachments.forEach((item: any) => {
        dispatch(
          createManual({
            name: item.name,
            data: item.data,

            machine: modalMachine,
            machine_subcategory: modalSubcategory,
            machine_manufacturer: modalManufacturer,
            machine_product_lines: modalProductLine,
            machine_model_types: modalModelType,
            projects: modalProjects,
            is_shared: isShared,
            description: modalDescription,
          })
        );
      });
    } else {
      dispatch(
        updateManual({
          id: id!,
          machine: modalMachine,
          machine_subcategory: modalSubcategory,
          machine_manufacturer: modalManufacturer,
          machine_product_lines: modalProductLine,
          machine_model_types: modalModelType,
          projects: modalProjects,
          is_shared: isShared,
          description: modalDescription,
        })
      );
    }
  }, [
    dispatch,
    attachments,
    modalMachine,
    modalSubcategory,
    modalManufacturer,
    modalProductLine,
    modalModelType,
    modalProjects,
    isShared,
    modalDescription,
    id,
  ]);

  const handleRemoveAttachment = (id: number) => {
    setAttachments((prevAttachments: any) =>
      prevAttachments.filter((item: any) => item.id !== id)
    );
  };

  return (
    <>
      <SingleActionModal
        open={open}
        modalTitle={t(`manualModal.titles.${modelType}`)}
        finishButtonLabel={t("machine.detail.uploadManualAttachment.confirm")}
        closeModal={onCancel}
        handleFinish={onFinish}
        submitDisabled={
          modelType === EModalTypes.create
            ? !attachments.length || !modalSubcategory
            : !modalSubcategory
        }
        className={classes.modal}
      >
        <Box>
          <Box
            sx={{
              width: { xs: "100%", md: "60%" },
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={1}
              sx={{
                width: "100%",
              }}
            >
              <Heading3>{t("createManuals.selectPdf")}</Heading3>
              <label
                htmlFor="upload-pdf"
                style={{
                  cursor:
                    modelType === EModalTypes.create ? "pointer" : "unset",
                  color:
                    modelType !== EModalTypes.create ? "#9f9f9f" : "inherit",
                  height: "1.5rem",
                }}
              >
                <input
                  style={{ display: "none" }}
                  id="upload-pdf"
                  name="upload-pdf"
                  type="file"
                  accept=".pdf"
                  multiple
                  onChange={onDragCapture}
                  disabled={modelType !== EModalTypes.create}
                />
                <Launch />
              </label>
            </Box>
            {attachments.length ? (
              <>
                {attachments.map((item: any) => {
                  return (
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <span>{item.name}</span>
                      <Button onClick={() => handleRemoveAttachment(item.id)}>
                        <CloseIcon />
                      </Button>
                    </Box>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={2.5}
          >
            <Heading3>{t("manualModal.manualHierarchy.title")}</Heading3>
            <Box
              sx={{
                width: { xs: "100%", sm: "60%" },
              }}
            >
              <CategoriesAndSubcategoriesFilter modalSelect={true} />
            </Box>

            <Box
              sx={{
                width: { xs: "100%", sm: "60%" },
              }}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Checkbox
                  label="manualModal.manualHierarchy.project.checkbox"
                  checked={isShared}
                  onChange={() => {
                    dispatch(actions.setIsShared(!isShared));
                  }}
                />
                <CompaniesAndProjectsFilter
                  disabled={isShared}
                  modalSelect={true}
                />
              </Box>
              <Paragraph fontSize={2} ml={2}>
                {t("manualModal.manualHierarchy.project.explanation")}
              </Paragraph>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              gap={1.5}
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  flexBasis: { xs: "100%", sm: "60%" },
                }}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap={1.5}
              >
                <MachineManufacturer modalSelect={true} />
                <MachineProductLine modalSelect={true} />
                <MachineModelType modalSelect={true} />
              </Box>
              {/* To be modified */}
              <Box
                sx={{
                  flexBasis: { xs: "100%", sm: "36%" },
                }}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap={1.5}
              >
                <Button>
                  {t("manualModal.manualHierarchy.button.addManufacturer")}
                </Button>
                <Button>
                  {t("manualModal.manualHierarchy.button.addProductLine")}
                </Button>
                <Button>
                  {t("manualModal.manualHierarchy.button.addModelType")}
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", sm: "60%" },
              }}
            >
              <Box display="flex" alignItems="center" gap={1}>
                <Checkbox
                  label="manualModal.manualHierarchy.machine.checkbox"
                  checked={isSpecificMachine}
                  onChange={() => {
                    dispatch(actions.setIsSpecificMachine(!isSpecificMachine));
                  }}
                />
                <Machine disabled={!isSpecificMachine} modalSelect={true} />
              </Box>
              <Paragraph fontSize={2} ml={2}>
                {t("manualModal.manualHierarchy.machine.explanation")}
              </Paragraph>
            </Box>
            <TextField
              label={t("manualModal.manualHierarchy.description")}
              value={modalDescription}
              onChange={(e: any) =>
                dispatch(actions.setModalDescription(e.target.value))
              }
              type="text"
              variant="outlined"
              size="small"
              sx={{
                width: { xs: "100%", sm: "60%" },
              }}
            />
          </Box>
        </Box>
      </SingleActionModal>
    </>
  );
};

export default CreateManuals;
