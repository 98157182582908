import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { IProjectList } from "types/project";
import { ResponseType } from "types/response";

type Params = {
  ids?: number[];
  page?: number;
  pageSize?: number;
  q?: string;
  nextUrl?: string;
};

async function getCompanyMap(companyIds: number[]) {
  if (!companyIds || !companyIds.length) {
    return {};
  }
  const { data } = await axiosNeuron.get(
    `${endpoints.companies.default}?id=${companyIds.join("&id=")}&ps=10000`
  );
  return data.results.reduce(
    (
      out: any,
      company: {
        id: number;
        name: string;
      }
    ) => ({
      ...out,
      [company.id]: company.name,
    }),
    {}
  );
}

export default async function getCompanyProjects(paramsInput: Params) {
  if (paramsInput.ids && !paramsInput.ids.length) {
    return {
      count: 0,
      results: [],
      next: null,
      previous: null,
    } as ResponseType<IProjectList>;
  }
  const params = {
    id: paramsInput.ids,
    page: paramsInput.page,
    ps: paramsInput.pageSize,
    q: paramsInput.q?.length ? paramsInput.q : undefined,
    is_archived: "false",
  };
  try {
    const response = await axiosNeuron.get(
      paramsInput.nextUrl || endpoints.projects.default,
      {
        params,
      }
    );
    const companyMap = await getCompanyMap(
      Array.from(
        new Set<number>(
          response.data.results
            .map((i: any) => i.company)
            .filter((i: any) => i !== null)
        )
      )
    );

    const data: ResponseType<IProjectList> = {
      ...response.data,
      results: response.data.results.map((item: any) => ({
        id: item.id,
        projectName: item.project_name,
        name: item.name,
        createdAt: item.created_at,
        description: item.description,
        type: item.type,
        bucket: item.bucket,
        company: item.company,
        users: item.users,
        user_ro: item.user_ro,
        companyName: companyMap[item.company] ? companyMap[item.company] : null,
      })),
    };

    return data;
  } catch (error) {
    if (error !== undefined) {
      return null;
    }
  }
}
