import React, { useMemo, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import {
  useTable,
  usePagination,
  useSortBy,
  Column,
  UseTableInstanceProps,
  SortingRule,
} from "react-table";
import { useTranslation } from "react-i18next";
import Footer from "./footer";
import Spinner from "components/spinner";
import Box from "@mui/material/Box";
import Heading4 from "components/typography/heading/heading4";

interface RowComponentProps<T extends object>
  extends Pick<UseTableInstanceProps<T>, "rows" | "prepareRow" | "allColumns"> {
  rowHeight?: number | string;
  onClick?: (id?: number) => void;
  height?: string | number;
}
interface HeaderComponentProps<T extends object>
  extends Pick<UseTableInstanceProps<T>, "flatHeaders"> {
  hide?: boolean;
}

type Props<T extends object> = {
  columns: Column<T>[];
  hiddenColumns?: string[];
  onPageChange?: (pageIndex: number) => void;
  onPageSort?: (sortBy: SortingRule<number>[]) => void;
  initialState?: {
    pageIndex?: number;
    pageSize?: number;
    pageCount?: number;
    sortBy?: SortingRule<number>[];
  };
  rowsCount: number;
  data: T[];
  hideNoData?: boolean;
  RowComponent: (props: RowComponentProps<T>) => JSX.Element;
  RowComponentProps?: {
    rowHeight?: number | string;
    rowWidth?: number | string;
    onClick?: (id?: number) => void;
    selected?: number[];
    height?: string | number;
  };
  HeaderComponent: (props: HeaderComponentProps<T>) => JSX.Element;
  HeaderComponentProps?: {
    hide?: boolean;
    height?: number | string;
    minWidth?: number | string;
  };
  isLoading: boolean;
  showMoreClickedTimesRef?: React.MutableRefObject<number>;
  hidePagination?: boolean;
  lazyLoad?: boolean;
  infinitePaging?: boolean;
  footerSpacing?: boolean;
  pmax?: number;
  tableClass?: any;
};

const useStyles = makeStyles((theme: any) => ({
  tableContent: {
    borderCollapse: "separate",
    width: "100%",
    borderSpacing: theme.spacing(0, 1),
    padding: theme.spacing(2, 0, 0, 0),
    tableLayout: "auto",
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
      padding: theme.spacing(0),
    },
  },
  filterRow: {
    display: "flex",
    alignItems: "center",
    height: theme.spacing(7),
  },
  thead: {
    transform: `translate(0, ${theme.spacing(0.5)})`,
  },
}));

function Table<T extends object>({
  columns,
  hiddenColumns,
  RowComponent,
  RowComponentProps,
  HeaderComponent,
  HeaderComponentProps,
  initialState,
  data,
  hideNoData,
  rowsCount,
  onPageChange,
  onPageSort,
  isLoading,
  hidePagination,
  showMoreClickedTimesRef,
  lazyLoad,
  infinitePaging,
  footerSpacing,
  pmax,
  tableClass,
}: Props<T>) {
  const classes = useStyles();
  const lColumns = useMemo(() => columns, [columns]);
  const prevPageIndex = useRef(initialState?.pageIndex);
  const prevSort = useRef(initialState?.sortBy);
  const { t } = useTranslation();
  const {
    getTableProps,
    flatHeaders,
    prepareRow,
    allColumns,
    // usePagination variables
    page,
    pageCount,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state,
    gotoPage,
  } = useTable<T>(
    {
      manualSortBy: true,
      disableSortBy: !onPageSort,
      manualPagination: true,
      pageCount: infinitePaging ? 1000000 : initialState?.pageCount,
      columns: lColumns,
      data,
      initialState: {
        pageIndex: initialState?.pageIndex,
        pageSize: initialState?.pageSize,
        sortBy: initialState?.sortBy || [],
        hiddenColumns: hiddenColumns || [],
      },
    },
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize, sortBy } = state;

  useEffect(() => {
    if (onPageSort) {
      if (
        JSON.stringify(prevSort.current) !== JSON.stringify(sortBy) &&
        onPageSort
      ) {
        onPageSort(sortBy);
      }
      prevSort.current = sortBy;
    }
  }, [onPageChange, onPageSort, pageIndex, sortBy]);

  useEffect(() => {
    if (prevPageIndex.current !== pageIndex && onPageChange) {
      onPageChange(pageIndex);
    }
    prevPageIndex.current = pageIndex;
  }, [onPageChange, pageIndex]);

  if (!data.length && !hideNoData) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={1}
        p={10}
      >
        <Heading4>{t("list.noData")}</Heading4>
      </Box>
    );
  }

  return (
    <div>
      <div style={{ overflowX: "auto" }}>
        <table
          className={classes.tableContent}
          {...getTableProps()}
          style={tableClass ? tableClass : undefined}
        >
          <thead className={classes.thead}>
            <HeaderComponent
              flatHeaders={flatHeaders}
              {...HeaderComponentProps}
            />
          </thead>
          <tbody>
            <RowComponent
              rows={page}
              prepareRow={prepareRow}
              allColumns={allColumns}
              {...RowComponentProps}
            />
          </tbody>
        </table>
      </div>
      {isLoading || (lazyLoad && <Spinner />)}
      {!isLoading && !hidePagination && showMoreClickedTimesRef && (
        <Footer
          rowCount={rowsCount}
          pageIndex={pageIndex}
          pmax={pmax}
          showMore={() => {
            showMoreClickedTimesRef.current =
              showMoreClickedTimesRef.current + 1;
            nextPage();
          }}
          pageCountFrom={
            showMoreClickedTimesRef.current === 0
              ? pageSize * pageIndex
              : pageSize * (pageIndex - showMoreClickedTimesRef.current)
          }
          pageCountTo={Math.min(pageSize * pageIndex + pageSize, rowsCount)}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage && pageIndex !== pageCount - 1}
          pageCount={pageCount}
          nextPage={() => {
            showMoreClickedTimesRef.current = 0;
            nextPage();
          }}
          previousPage={() => {
            showMoreClickedTimesRef.current = 0;
            previousPage();
          }}
          gotoPage={(index: number) => {
            showMoreClickedTimesRef.current = 0;
            gotoPage(index);
          }}
          infinitePaging={infinitePaging}
          footerSpacing={footerSpacing}
        />
      )}
    </div>
  );
}

export default Table;
