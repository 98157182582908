import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import { useAsyncReducer } from "store/selectTemplate";
import actions from "store/manuals/actions";
import SelectField from "components/selectField";
import getMachineProductLines from "api/handlers/machine/getMachineProductLines";
import getMachineModelTypes from "api/handlers/machine/getMachineModelTypes";

const MachineModelType = ({ modalSelect = false }: any) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();

  const {
    modalProductLine,
    machineProductLines,
    machineModelTypes,
    modalModelTpe,
  } = useSelector((state: AppState) => state.manuals);

  const { data, loading } = useAsyncReducer(
    "machineModelTypeSelect",
    getMachineModelTypes,
    {
      product_line: modalSelect ? modalProductLine : machineProductLines,
    }
  );

  const modelTypes = useMemo(() => {
    let options: any = [];
    if (data) {
      options = data.results.map((item: any) => ({
        text: item.name,
        value: item.id,
      }));
    }
    return options;
  }, [data]);

  const onChange = useCallback(
    (values: number[] | string[]) => {
      if (modalSelect) {
        dispatch(actions.setModalModelType(values.length ? values : null));
      } else {
        dispatch(actions.setMachineModelTypes(values.length ? values : null));
      }
    },
    [dispatch]
  );

  return (
    <>
      <SelectField
        searchPlaceholder={t(
          modalSelect
            ? "manualModal.manualHierarchy.machineModelType.searchPlaceholder"
            : "search.searchMachineModelType"
        )}
        initialValue={
          modalSelect
            ? modalModelTpe || undefined
            : machineModelTypes || undefined
        }
        loading={!data || loading}
        disabled={false}
        name="testMachineModelTypeSelect"
        label={
          modalSelect
            ? "manualModal.manualHierarchy.machineModelType.label"
            : t("manual.filter.machineModelType")
        }
        id="testMachineModelTypeSelect"
        options={modelTypes}
        onChangeSubmit={onChange}
        data-testid="select-field-machineModelType"
      />
    </>
  );
};

export default MachineModelType;
