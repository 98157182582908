import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IMuiTheme from "types/styles";

export const useStyles = makeStyles((theme: IMuiTheme | Theme) => ({
  root: {
    width: "100%",
    "& .MuiSelect-select": {
      height: "auto",
      minHeight: theme.typography.pxToRem(20),
      paddingTop: theme.typography.pxToRem(9),
      paddingBottom: theme.typography.pxToRem(9),
    },
    "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
      fontSize: "inherit",
    },
  },

  renderWrap: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(-1.25),
  },
  renderWrapBox: {
    maxWidth: "100%",
  },
  last: { maxWidth: "50%" },
  andMoreText: {
    maxWidth: "30%",
    color: theme.custom.palette.label,
    fontFamily: theme.custom.typography.fontFamily,
    fontSize: theme.custom.typography.fontSize["11"],
    fontWeight: "bold",
    display: "inline-block",
    padding: ".1875rem .5rem .125rem .25rem",
    textTransform: "uppercase",
    letterSpacing: ".04rem",
    lineHeight: 1.2,
  },
  searchIcon: {
    color: theme.custom.palette.placeholder,
  },
  textField: {
    height: theme.spacing(4),
  },
  searchInput: {
    fontSize: theme.custom.typography.fontSize[14],
    fontWeight: theme.custom.typography.fontWeight.normal,
    color: theme.custom.palette.data,
    letterSpacing: theme.custom.typography.letterSpacing.small,
    "&::placeholder": {
      fontSize: theme.custom.typography.fontSize[14],
      fontWeight: theme.custom.typography.fontWeight.normal,
      color: theme.custom.palette.placeholder,
      letterSpacing: theme.custom.typography.letterSpacing.small,
    },
  },
  searchBox: {
    boxShadow: `0 ${theme.spacing(0.5)} ${theme.spacing(0.5)} -${theme.spacing(
      0.5
    )} ${theme.custom.palette.shadow}`,
  },
  groupHeader: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  loadMoreItem: {
    display: "flex",
    justifyContent: "center",
  },
}));
