import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import { SortingRule } from "react-table";

type TProps = {
  page?: number;
  ps?: number;
  orderBy?: SortingRule<number>[];
  machine?: number[] | null;
  machine_subcategory?: number[] | null;
  machine_manufacturer?: number[] | null;
  machine_model_type?: number[] | null;
  machine_product_line?: number[] | null;
  project?: number[] | null;
  q?: string | null;
};

async function getUserMap(userIds: number[]) {
  if (!userIds || !userIds.length) {
    return {};
  }
  const { data: userData } = await axiosNeuron.get(
    `${endpoints.users.default}?id=${userIds.join("&id=")}&ps=10000`
  );
  return userData.results.reduce(
    (
      out: any,
      user: {
        id: number;
        username: string;
        first_name: string;
        last_name: string;
      }
    ) => ({
      ...out,
      [user.id]:
        user.first_name || user.last_name
          ? `${user?.first_name} ${user?.last_name}`
          : user.username,
    }),
    {}
  );
}

async function getMachineMap(machineIds: number[]) {
  if (!machineIds || !machineIds.length) {
    return {};
  }
  const { data } = await axiosNeuron.get(
    `${endpoints.machines.default}?id=${machineIds.join("&id=")}&ps=10000`
  );
  return data.results.reduce(
    (
      out: any,
      machine: {
        id: number;
        name: string;
      }
    ) => ({
      ...out,
      [machine.id]: machine.name,
    }),
    {}
  );
}

async function getMachineManufacturerMap(machineManufacturerIds: number[]) {
  if (!machineManufacturerIds || !machineManufacturerIds.length) {
    return {};
  }
  const { data } = await axiosNeuron.get(
    `${endpoints.machines.machineManufacturers}?id=${machineManufacturerIds.join("&id=")}&ps=10000`
  );
  return data.results.reduce(
    (
      out: any,
      machineManufacturer: {
        id: number;
        name: string;
      }
    ) => ({
      ...out,
      [machineManufacturer.id]: machineManufacturer.name,
    }),
    {}
  );
}

async function getMachineProductLineMap(machineProductLineIds: number[]) {
  const ids =
    !machineProductLineIds || !machineProductLineIds.length
      ? []
      : Array.from(new Set(machineProductLineIds.flatMap((i) => i)));

  if (!ids || !ids.length) {
    return {};
  }
  const { data } = await axiosNeuron.get(
    `${endpoints.machines.machineProductLines}?id=${ids.join("&id=")}&ps=10000`
  );
  return data.results.reduce(
    (
      out: any,
      machineProductLine: {
        id: number;
        name: string;
      }
    ) => ({
      ...out,
      [machineProductLine.id]: machineProductLine.name,
    }),
    {}
  );
}
async function getMachineModelTypeMap(machineModelTypeIds: number[]) {
  const ids =
    !machineModelTypeIds || !machineModelTypeIds.length
      ? []
      : Array.from(new Set(machineModelTypeIds.flatMap((i) => i)));
  if (!ids || !ids.length) {
    return {};
  }
  const { data } = await axiosNeuron.get(
    `${endpoints.machines.machineModelTypes}?id=${ids.join("&id=")}&ps=10000`
  );
  return data.results.reduce(
    (
      out: any,
      machineModelType: {
        id: number;
        name: string;
      }
    ) => ({
      ...out,
      [machineModelType.id]: machineModelType.name,
    }),
    {}
  );
}

async function getCompaniesMap(companyIds: number[]) {
  if (!companyIds || !companyIds.length) {
    return {};
  }
  const { data } = await axiosNeuron.get(
    `${endpoints.companies.default}?id=${companyIds.join("&id=")}&ps=10000`
  );
  return data.results.reduce(
    (
      out: any,
      company: {
        id: number;
        name: string;
      }
    ) => ({
      ...out,
      [company.id]: company.name,
    }),
    {}
  );
}

async function getProjectsMap(projectIds: number[]) {
  const ids =
    !projectIds || !projectIds.length
      ? []
      : Array.from(new Set(projectIds.flatMap((i) => i)));

  if (!ids || !ids.length) {
    return {};
  }
  const { data } = await axiosNeuron.get(
    `${endpoints.projects.default}?id=${ids.join("&id=")}&ps=10000`
  );

  const companyMap = await getCompaniesMap(
    Array.from(
      new Set<number>(
        data.results.map((i: any) => i.company).filter((i: any) => i !== null)
      )
    )
  );

  return data.results.reduce(
    (
      out: any,
      project: {
        id: number;
        name: string;
        company: number;
      }
    ) => {
      return {
        ...out,
        [project.id]: {
          projectName: project.name,
          companyName: companyMap[project.company]
            ? companyMap[project.company]
            : null,
        },
      };
    },
    {}
  );
}

async function getMachineCategoriesMap(categoryIds: number[]) {
  if (!categoryIds || !categoryIds.length) {
    return {};
  }
  const { data } = await axiosNeuron.get(
    `${endpoints.machines.categories}?id=${categoryIds.join("&id=")}&ps=10000`
  );
  return data.results.reduce(
    (
      out: any,
      category: {
        id: number;
        name: string;
      }
    ) => ({
      ...out,
      [category.id]: category.name,
    }),
    {}
  );
}

async function getMachineSubcategoriesMap(subcategoryIds: number[]) {
  if (!subcategoryIds || !subcategoryIds.length) {
    return {};
  }
  const { data } = await axiosNeuron.get(
    `${endpoints.machines.subCategories}?id=${subcategoryIds.join("&id=")}&ps=10000`
  );

  const companyMap = await getMachineCategoriesMap(
    Array.from(
      new Set<number>(
        data.results.map((i: any) => i.category).filter((i: any) => i !== null)
      )
    )
  );

  return data.results.reduce(
    (
      out: any,
      subcategory: {
        id: number;
        name: string;
        category: number;
      }
    ) => {
      return {
        ...out,
        [subcategory.id]: {
          subcategoryName: subcategory.name,
          categoryName: companyMap[subcategory.category]
            ? companyMap[subcategory.category]
            : null,
          category: subcategory.category,
        },
      };
    },
    {}
  );
}

export default async function getManuals({
  page,
  ps,
  orderBy,
  machine,
  machine_subcategory,
  machine_manufacturer,
  machine_model_type,
  machine_product_line,
  project,
  q,
}: TProps) {
  const url = endpoints.machines.manualAttachements;
  const orderByString = orderBy?.length
    ? `&order_by=${orderBy
        .map((item: any) => `${!item.desc ? "-" : ""}${item.id}`)
        .join("&order_by=")}`
    : "";
  try {
    const { data } = await axiosNeuron.get(`${url}?${orderByString}`, {
      params: {
        machine: machine && machine.length ? machine : undefined,
        page: page ? page! + 1 : undefined,
        ps,
        machine_subcategory:
          machine_subcategory && machine_subcategory.length
            ? machine_subcategory
            : undefined,
        machine_manufacturer:
          machine_manufacturer && machine_manufacturer.length
            ? machine_manufacturer
            : undefined,
        machine_model_type:
          machine_model_type && machine_model_type.length
            ? machine_model_type
            : undefined,
        machine_product_line:
          machine_product_line && machine_product_line.length
            ? machine_product_line
            : undefined,
        project: project && project.length ? project : undefined,
        q: q ? q : undefined,
      },
    });

    const results = data.results.map((i: any, idx: number) => ({
      ...i,
      userId: i.modified_by_user ? i.modified_by_user : i.created_by_user,
    }));

    const userMap = await getUserMap(
      Array.from(
        new Set<number>(
          results
            .map((i: any) => {
              return i.userId;
            })
            .filter((i: any) => i !== null)
        )
      )
    );
    const machineMap: any = await getMachineMap(
      Array.from(
        new Set<number>(
          results.map((i: any) => i.machine).filter((i: any) => i !== null)
        )
      )
    );

    const machineManufacturerMap = await getMachineManufacturerMap(
      Array.from(
        new Set<number>(
          results
            .map((i: any) => i.machine_manufacturer)
            .filter((i: any) => i !== null)
        )
      )
    );
    const machineProductLineMap = await getMachineProductLineMap(
      Array.from(
        new Set<number>(
          results
            .map((i: any) => {
              return i.machine_product_lines;
            })
            .filter((i: any) => i !== null)
        )
      )
    );
    const machineModelTypeMap = await getMachineModelTypeMap(
      Array.from(
        new Set<number>(
          results
            .map((i: any) => {
              return i.machine_model_types;
            })
            .filter((i: any) => i !== null)
        )
      )
    );

    const projectMap = await getProjectsMap(
      Array.from(
        new Set<number>(
          results.map((i: any) => i.projects).filter((i: any) => i !== null)
        )
      )
    );

    const machineSubcategoryMap = await getMachineSubcategoriesMap(
      Array.from(
        new Set<number>(
          results
            .map((i: any) => i.machine_subcategory)
            .filter((i: any) => i !== null)
        )
      )
    );

    const newData = {
      ...data,
      results: results.map((item: any) => {
        const projects = item.projects?.length
          ? item.projects
              .map((i: number) => projectMap[i])
              .filter((i: any) => i !== null && i !== undefined)
          : null;

        const machineSubcategories = machineSubcategoryMap[
          item.machine_subcategory
        ]
          ? machineSubcategoryMap[item.machine_subcategory]
          : null;

        const machineProductLines = item.machine_product_lines.length
          ? item.machine_product_lines
              .map((i: number) => machineProductLineMap[i])
              .filter((i: any) => i !== null && i !== undefined)
          : null;

        const machineModelTypes = item.machine_model_types.length
          ? item.machine_model_types
              .map((i: number) => machineModelTypeMap[i])
              .filter((i: any) => i !== null && i !== undefined)
          : null;

        return {
          ...item,
          user: userMap[item.userId] ? userMap[item.userId] : null,
          machineName: machineMap[item.machine]
            ? machineMap[item.machine]
            : null,
          machineManufacturerName: machineManufacturerMap[
            item.machine_manufacturer
          ]
            ? machineManufacturerMap[item.machine_manufacturer]
            : null,
          machineProductLineName: machineProductLines
            ? machineProductLines
            : null,
          machineModelTypeName: machineModelTypes ? machineModelTypes : null,
          companyName: projects
            ? projects.map((pr: any) => pr.companyName)
            : null,
          projectName: projects
            ? projects.map((pr: any) => pr.projectName)
            : null,
          machine_category: machineSubcategories
            ? machineSubcategories.category
            : null,
          machineCategoryName: machineSubcategories
            ? machineSubcategories.categoryName
            : null,
          machineSubcategoryName: machineSubcategories
            ? machineSubcategories.subcategoryName
            : null,
        };
      }),
    };
    return newData;
  } catch (error: any) {
    console.log("Error fetching manuals:", error);
    if (error !== undefined) {
      throw error;
    }
  }
}
