import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Heading4 from "components/typography/heading/heading4";
import IMuiTheme from "types/styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const useStyles = makeStyles((theme: IMuiTheme) => ({
  ellipsisBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: "5px",
  },
  ellipsis: {
    display: "table-cell",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
}));

const File = ({
  cell: {
    value: { attachment },
  },
}: any) => {
  const classes = useStyles();
  return (
    <Box className={classes.ellipsisBox}>
      <PictureAsPdfIcon />
      <Box className={classes.ellipsis} title={attachment.split("/").pop()}>
        {attachment.split("/").pop()}
      </Box>
    </Box>
  );
};

export default File;
