import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import { useAsyncReducer } from "store/selectTemplate";
import actions from "store/manuals/actions";
import getMachineManufacturers from "api/handlers/machine/getMachineManufacturers";
import SelectField from "components/selectField";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  filter: {
    width: "100%",

    "& .MuiSelect-select": {
      paddingTop: "11.5px",
      paddingBottom: "11.5px",
    },
  },
}));

const MachineManufacturer = ({ modalSelect = false }: any) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const { machineManufacturers, modalManufacturer } = useSelector(
    (state: AppState) => state.manuals
  );

  const { data, loading } = useAsyncReducer(
    "machineManufacturerSelect",
    getMachineManufacturers,
    {}
  );

  const manufacturer = useMemo(() => {
    let options: any = [];
    if (data) {
      options = data.results.map((item: any) => ({
        text: item.name,
        value: item.id,
      }));
    }
    return modalSelect
      ? [
          {
            text: t("manual.machineHierarchy.unassigned"),
            value: "",
          },
          ...options,
        ]
      : options;
  }, [data]);

  const onChange = useCallback(
    (values: number[] | string[]) => {
      if (modalSelect) {
        dispatch(actions.setModalManufacturer(values.length ? values : null));
      } else {
        dispatch(
          actions.setMachineManufacturers(values.length ? values : null)
        );
      }
    },
    [dispatch]
  );
  const onChangeModalSelect = useCallback(
    (event: any) => {
      dispatch(actions.setModalManufacturer(event.target.value));
    },
    [dispatch]
  );

  return (
    <>
      {modalSelect ? (
        <FormControl variant="outlined" className={classes.filter}>
          <InputLabel id="testMachineManufacturerSelect">
            {t("manualModal.manualHierarchy.machineManufacturer.label")}
          </InputLabel>
          <Select
            id="testMachineManufacturerSelect"
            labelId="testMachineManufacturerSelect"
            label={t("manualModal.manualHierarchy.machineManufacturer.label")}
            value={modalManufacturer}
            onChange={onChangeModalSelect}
            placeholder={t(
              "manualModal.manualHierarchy.machineManufacturer.searchPlaceholder"
            )}
          >
            {manufacturer.map((option: any) => {
              return (
                <MenuItem value={option.value} key={option.value}>
                  {option.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <SelectField
          searchPlaceholder={t("search.searchMachineManufacturer")}
          initialValue={machineManufacturers || undefined}
          loading={!data || loading}
          disabled={false}
          name="machineManufacturerFilter"
          label={t("manual.filter.machineManufacturer")}
          id="testMachineManufacturerSelect"
          options={manufacturer}
          onChangeSubmit={onChange}
          data-testid="select-field-machineManufacturerFilter"
        />
      )}
    </>
  );
};

export default MachineManufacturer;
