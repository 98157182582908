import React, { memo, useMemo, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { KebabMenu } from "components/kebab";
import { Theme } from "@mui/material";
import ManualModal from "../createManuals";
import { EModalTypes } from "store/manuals/types";
import actions from "store/manuals/actions";

const useStyles = makeStyles((theme: Theme) => {
  return {
    item: {
      fontSize: theme.custom.typography.fontSize[14],
      color: theme.custom.palette.data,
      fontWeight: theme.custom.typography.fontWeight.normal,
    },
  };
});

const ActionsButton = memo(
  ({
    cell: {
      value: {
        id,
        machine_category,
        is_shared,
        description,
        machine_subcategory,
        machine_manufacturer,
        machine_product_lines,
        machine_model_types,
        machine,
        projects,
      },
    },
  }: any) => {
    const dispatch: any = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    const [editModal, setEditModal] = useState(false);

    const hideKebabMenu = useCallback(() => {
      const hideKebab = new CustomEvent("hideKebab");
      const kebab = document.querySelector("#kebab-devices-list-" + id);
      kebab?.dispatchEvent(hideKebab);
    }, [id]);

    const onEdit = useCallback(() => {
      hideKebabMenu();

      dispatch(actions.setIsShared(is_shared));
      dispatch(actions.setModalDescription(description));
      dispatch(
        actions.setModalSubcategory(
          machine_subcategory ? machine_subcategory : null
        )
      );

      dispatch(actions.setModalManufacturer(machine_manufacturer));
      dispatch(
        actions.setModalProductLine(
          machine_product_lines ? machine_product_lines : null
        )
      );
      dispatch(
        actions.setModalModelType(
          machine_model_types ? machine_model_types : null
        )
      );
      dispatch(actions.setModalMachine(machine));
      dispatch(actions.setModalProjects(projects ? projects : null));
      dispatch(actions.setIsSpecificMachine(machine ? true : false));

      setEditModal(true);
    }, [
      hideKebabMenu,
      id,
      editModal,
      is_shared,
      description,
      machine_subcategory,
      machine_manufacturer,
      machine_product_lines,
      machine_model_types,
      machine,
      projects,
      machine,
    ]);

    const generateItems = useMemo(() => {
      return (
        <>
          <MenuItem className={classes.item} onClick={onEdit}>
            {t("manual.detail.table.edit")}
          </MenuItem>
        </>
      );
    }, [classes.item, onEdit, t]);

    return (
      <>
        <KebabMenu id={"kebab-devices-list-" + id} data={generateItems} />

        {editModal ? (
          <ManualModal
            open={editModal}
            closeModal={() => setEditModal(false)}
            modelType={EModalTypes.edit}
            id={id}
          />
        ) : null}
      </>
    );
  }
);

export default ActionsButton;
