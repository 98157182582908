import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box, useMediaQuery } from "@mui/material";
import Table from "components/table";
import Header from "components/table/header";
import Row from "components/table/row";
import ConfirmPopup from "components/confirmPopup";
import { IManual } from "types/machine";
import createColumns from "./columns";
import { deleteMachineAttachment } from "store/machineDetail/machineManuals/actions";
import { SortingRule } from "react-table";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { createTinyColumns } from "./tinyColumn";

interface IProps {
  data: IManual[];
  loading: boolean;
  initialState?: any;
  onPageSort?: (sortBy: SortingRule<number>[]) => void;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    "& td": {
      borderBottom: "unset!important",
      "&:first-child": {
        borderTopLeftRadius: "0!important",
        borderLeft: "unset!important",
      },
      "&:last-child": {
        borderTopRightRadius: "0!important",
        borderRight: "unset!important",
      },
    },
  },
}));

const Details = ({ data, loading, initialState, onPageSort }: IProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const classes = useStyles();

  const handleDelete = useCallback((id: number) => {
    setDeleteId(id);
  }, []);

  const handleConfirm = () => {
    if (deleteId) {
      dispatch(deleteMachineAttachment(deleteId!));
      setDeleteId(null);
    }
  };

  const sortManuals = (manuals: any) => {
    const sortedManuals = manuals.sort((a: any, b: any) => {
      if (a.machine === null && b.machine !== null) {
        return -1;
      } else if (a.machine !== null && b.machine === null) {
        return 1;
      } else {
        return 0;
      }
    });

    const hasNullMachine = sortedManuals.some(
      (item: any) => item.machine === null
    );
    const firstNonNullMachineIndex = sortedManuals.findIndex(
      (item: any) => item.machine !== null
    );

    if (hasNullMachine && firstNonNullMachineIndex !== -1) {
      sortedManuals[firstNonNullMachineIndex].isMachine = true;
    }

    return sortedManuals;
  };

  const sortedManuals = useMemo(() => {
    if (data.length) {
      return sortManuals(data);
    }
  }, [data]);

  const tinyColumnsMemoized = useMemo(
    () => createTinyColumns(handleDelete),
    [t, handleDelete]
  );
  const columnsMemoized = useMemo(
    () => createColumns(t, handleDelete),
    [t, handleDelete]
  );
  const columns = upMd ? columnsMemoized : tinyColumnsMemoized;

  return (
    <Box className={classes.root}>
      <Table
        isLoading={loading}
        data={sortedManuals}
        rowsCount={0}
        columns={columns}
        initialState={initialState}
        RowComponent={Row}
        HeaderComponent={Header}
        RowComponentProps={{
          rowWidth: "100%",
        }}
        HeaderComponentProps={{ height: "unset", minWidth: "6rem" }}
        hideNoData={loading && !!data.length}
        tableClass={{
          borderSpacing: "0",
          borderCollapse: "separate",
        }}
        onPageSort={onPageSort}
      />
      {deleteId && (
        <ConfirmPopup
          onConfirm={handleConfirm}
          title={t("manualAttachment.delete.confirm.title")}
          text={t("manualAttachment.delete.confirm.text")}
          confirmText={t(
            "machine.detail.manualAttachment.delete.confirm.button"
          )}
          noControl={true}
          onCancel={() => setDeleteId(null)}
        />
      )}
    </Box>
  );
};

export default Details;
