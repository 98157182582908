import { Column } from "react-table";
import { TFunction } from "i18next";
import { IManual } from "types/machine";
import File from "./cells/file";
import DownloadAndDelete from "./cells/downloadAndDelete";
import CompanyAndProject from "./cells/companyAndProject";
import Note from "./cells/note";
import ActionsButton from "./cells/actionsButton";
import Regular from "./cells/regular";
import RegularList from "./cells/regularList";

const columns = (t: TFunction, handleDelete: any): Column<IManual>[] =>
  [
    {
      id: "project",
      Header: t("manual.detail.companyAndProject").toString(),
      accessor: ({ is_shared, companyName, projectName, isMachine }) => {
        return {
          is_shared,
          companyName,
          projectName,
          isMachine,
        };
      },
      Cell: CompanyAndProject,
      custom: {
        hidePriority: 0,
        margin: "0 0 0 1rem",
        customBorderBottom: ({ row }: any) =>
          row.original.isMachine && "5px solid #9f9f9f",
      },
    },
    {
      id: "machine_manufacturer",
      Header: t("manual.detail.manufacturer").toString(),
      accessor: ({ machineManufacturerName, isMachine }) => {
        return {
          label: machineManufacturerName ? machineManufacturerName : "-",
          isMachine,
        };
      },
      Cell: Regular,
      custom: {
        hidePriority: 0,
        margin: "0 0 0 1rem",
        customBorderBottom: ({ row }: any) =>
          row.original.isMachine && "5px solid #9f9f9f",
      },
    },
    {
      id: "machine_product_line",
      Header: t("manual.detail.machineProductLine").toString(),
      accessor: ({ machineProductLineName, isMachine }) => {
        return {
          label: machineProductLineName,
          isMachine,
        };
      },
      Cell: RegularList,
      custom: {
        hidePriority: 0,
        margin: "0 0 0 1rem",
        customBorderBottom: ({ row }: any) =>
          row.original.isMachine && "5px solid #9f9f9f",
      },
    },
    {
      id: "machine_model_type",
      Header: t("manual.detail.machineModelType").toString(),
      accessor: ({ machineModelTypeName, isMachine }) => {
        return {
          label: machineModelTypeName,
          isMachine,
        };
      },
      Cell: RegularList,
      custom: {
        hidePriority: 0,
        margin: "0 0 0 1rem",
        customBorderBottom: ({ row }: any) =>
          row.original.isMachine && "5px solid #9f9f9f",
      },
    },
    {
      id: "machine",
      Header: t("manual.detail.machine").toString(),
      accessor: ({ machineName, isMachine }) => {
        return {
          label: machineName ? machineName : "-",
          isMachine,
        };
      },
      Cell: Regular,
      custom: {
        hidePriority: 0,
        margin: "0 0 0 1rem",
        customBorderBottom: ({ row }: any) =>
          row.original.isMachine && "5px solid #9f9f9f",
      },
    },
    {
      id: "attachment",
      Header: t("manual.detail.file").toString(),
      accessor: ({ attachment, isMachine }) => {
        return {
          attachment,
          isMachine,
        };
      },
      Cell: File,
      custom: {
        hidePriority: 0,
        margin: "0 0 0 1rem",
        customBorderBottom: ({ row }: any) =>
          row.original.isMachine && "5px solid #9f9f9f",
      },
    },
    {
      id: "description",
      Header: t("manual.detail.note").toString(),
      accessor: ({ description, isMachine }) => {
        return {
          description,
          isMachine,
        };
      },
      Cell: Note,
      custom: {
        hidePriority: 0,
        margin: "0 0 0 1rem",
        customBorderBottom: ({ row }: any) =>
          row.original.isMachine && "5px solid #9f9f9f",
      },
    },
    {
      id: "downloadAndDelete",
      Header: "",
      accessor: ({ link, id, isMachine }) => {
        return {
          link,
          handleDelete: () => handleDelete(id),
          isMachine,
        };
      },
      Cell: DownloadAndDelete,
      custom: {
        hidePriority: 0,
        margin: "0 0 0 1rem",
        customBorderBottom: ({ row }: any) =>
          row.original.isMachine && "5px solid #9f9f9f",
      },
    },
    {
      id: "kebab",
      Header: "",
      accessor: ({
        id,
        machine_category,
        is_shared,
        description,
        machine_subcategory,
        machine_manufacturer,
        machine_product_lines,
        machine_model_types,
        machine,
        projects,
        isMachine,
      }) => {
        return {
          id,
          machine_category,
          is_shared,
          description,
          machine_subcategory,
          machine_manufacturer,
          machine_product_lines,
          machine_model_types,
          machine,
          projects,
          isMachine,
        };
      },
      Cell: ActionsButton,
      custom: {
        hidePriority: 0,
        margin: "0 0 0 1rem",
        customBorderBottom: ({ row }: any) =>
          row.original.isMachine && "5px solid #9f9f9f",
        alignRight: true,
      },
    },
  ] as Column<IManual>[] | any[];

export default columns;
