import React from "react";
import { Modal } from "components/modal";
import Header from "./header";
import Footer from "./footer";
import { makeStyles } from "@mui/styles";

interface Props {
  modalTitle: string;
  open: boolean;
  finishButtonLabel: string;
  submitDisabled?: boolean;
  handleFinish: () => void;
  closeModal: () => void;
  headerButtonText?: string;
  headerButtonClick?: () => void;
  headerButtonDisplay?: boolean;
  headerCircleButtonIcon?: any;
  headerCircleButtonClick?: () => void;
  headerCircleButtonDisplay?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const useStyles = makeStyles((theme: any) => ({
  content: {
    flexGrow: 1,
    overflowY: "auto",
    padding: theme.spacing(4),
    fontFamily: theme.custom.typography.fontFamily,
    display: "flex",
    flexDirection: "column",
  },
}));

const SingleActionModal: React.FC<Props> = ({
  modalTitle,
  open,
  finishButtonLabel,
  handleFinish,
  closeModal,
  children,
  submitDisabled,
  headerButtonClick,
  headerButtonText,
  headerButtonDisplay,
  headerCircleButtonIcon,
  headerCircleButtonClick,
  headerCircleButtonDisplay,
  loading,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Modal onCancel={closeModal} open={open} loading={loading} {...rest}>
      <>
        <Header
          modalTitle={modalTitle}
          buttonClick={headerButtonClick}
          buttonText={headerButtonText}
          buttonDisplay={headerButtonDisplay}
          circleButtonIcon={headerCircleButtonIcon}
          circleButtonClick={headerCircleButtonClick}
          circleButtonDisplay={headerCircleButtonDisplay}
        />
        <div className={classes.content}>{children}</div>
        <Footer
          onCancel={closeModal}
          finishButtonLabel={finishButtonLabel}
          handleFinish={handleFinish}
          submitDisabled={submitDisabled || false}
        />
      </>
    </Modal>
  );
};

export default SingleActionModal;
