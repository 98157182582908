import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import { useAsyncReducer } from "store/selectTemplate";
import MultiSelectGroupSearch from "components/eventSounds/components/filters/multiSelectGroupSearch";
import getCompanyProjects from "api/handlers/project/getProjectsCompany";
import actions from "store/manuals/actions";

const CompaniesAndProjectsFilter = ({
  modalSelect = false,
  disabled = false,
}: any) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const [searchPhrase, setSearchPhrase] = useState("");

  const { companyProjects, modalProjects } = useSelector(
    (state: AppState) => state.manuals
  );

  const {
    data: projects,
    loading,
    loadingMore,
    loadMore,
  } = useAsyncReducer("projectsSelect", getCompanyProjects, {
    pageSize: 100,
    q: searchPhrase,
    ids: modalSelect
      ? modalProjects
        ? [modalProjects]
        : undefined
      : companyProjects
        ? companyProjects
        : undefined,
  });

  const groupedProjects = projects
    ? projects.results.reduce((acc: any[], project: any) => {
        const companyId = project.company;

        const existingGroup =
          acc && acc?.find((item: any) => item.group === companyId);

        if (existingGroup) {
          existingGroup.items.push(project);
        } else {
          acc.push({ group: companyId, items: [project] });
        }

        return acc;
      }, [])
    : [];

  const updatedMergedProjects = useMemo(() => {
    return groupedProjects.map((project: any) => ({
      ...project,
      group: project.items[0].companyName,
      items: project.items.map((item: any) => ({
        name: item.name,
        id: item.id,
      })),
    }));
  }, [groupedProjects]);

  const onChange = useCallback(
    (values: number[] | string[]) => {
      if (modalSelect) {
        dispatch(actions.setModalProjects(values.length ? values : null));
      } else {
        dispatch(actions.setCompanyProjects(values.length ? values : null));
      }
    },
    [dispatch]
  );

  return (
    <>
      <MultiSelectGroupSearch
        id="company_and_projects"
        name="companyAndProjects"
        options={updatedMergedProjects}
        selectedValues={
          modalSelect ? modalProjects || [] : companyProjects || []
        }
        onChange={onChange}
        search={searchPhrase}
        label={t(
          modalSelect
            ? "manualModal.manualHierarchy.project.label"
            : "manual.filter.companyAndProjects"
        )}
        placeholder={t(
          modalSelect
            ? "manualModal.manualHierarchy.project.searchPlaceholder"
            : "search.searchcompanyAndProjects"
        )}
        setSearchPhrase={setSearchPhrase}
        loading={loading}
        loadMore={loadMore}
        loadingMore={loadingMore}
        disabled={disabled}
      />
    </>
  );
};

export default CompaniesAndProjectsFilter;
