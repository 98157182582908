import React from "react";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

type Props = {
  breakLine?: boolean;
};

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: "inline-block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
      [theme.breakpoints.up("sm")]: {
        marginBottom: 0,
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    labelTop: {
      width: "inherit",
      whiteSpace: (props: Props) => (props?.breakLine ? "normal" : "nowrap"),
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  };
});

type TRegularProps = {
  cell: {
    value: { label: string[]; classN?: string; breakLine?: boolean };
  };
};

const RegularList = (props: TRegularProps) => {
  const {
    cell: {
      value: { label, classN, breakLine },
    },
  } = props;
  const classes = useStyles({ breakLine });
  return (
    <span className={classes.root}>
      {label && label.length ? (
        <span className={clsx(classes.labelTop, classN || "")}>
          {label.map((lab: string) => lab).join(", ")}
        </span>
      ) : (
        "-"
      )}
    </span>
  );
};

export default RegularList;
