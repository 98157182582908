import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type TProps = {
  machine?: number;
  name: string;
  data: string;

  machine_subcategory?: number | null;
  machine_manufacturer?: number | null;
  machine_product_lines?: number[] | null;
  machine_model_types?: number[] | null;
  projects?: number[] | null;
  is_shared?: boolean;
  description?: string | null;
};

export default async function addMachineManual({
  machine,
  name,
  data,
  machine_subcategory,
  machine_manufacturer,
  machine_product_lines,
  machine_model_types,
  projects,
  is_shared,
  description,
}: TProps) {
  const url = endpoints.machines.manualAttachements;
  const params = {
    machine: machine ? machine : null,
    attachment: {
      name: name,
      data: data,
    },
    machine_subcategory: machine_subcategory ? machine_subcategory : null,
    machine_manufacturer: machine_manufacturer ? machine_manufacturer : null,
    machine_product_lines:
      machine_product_lines && machine_product_lines.length
        ? machine_product_lines
        : [],
    machine_model_types:
      machine_model_types && machine_model_types.length
        ? machine_model_types
        : [],
    projects: projects && projects.length ? projects : [],
    is_shared: is_shared,
    description: description ? description : null,
  };
  try {
    const { data } = await axiosNeuron.post(url, params);

    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
