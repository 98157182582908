import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  id: number;
  machine?: number;
  machine_subcategory?: number | null;
  machine_manufacturer?: number | null;
  machine_product_lines?: number[] | null;
  machine_model_types?: number[] | null;
  projects?: number[] | null;
  is_shared?: boolean;
  description?: string | null;
};

export default async function patchMachineManual({
  id,
  machine,
  machine_subcategory,
  machine_manufacturer,
  machine_product_lines,
  machine_model_types,
  projects,
  is_shared,
  description,
}: Params) {
  const url = endpoints.machines.detailManualAttachements(id);
  try {
    const response = await axiosNeuron.patch(url, {
      machine: machine ? machine : null,
      machine_subcategory: machine_subcategory ? machine_subcategory : null,
      machine_manufacturer: machine_manufacturer ? machine_manufacturer : null,
      machine_product_lines:
        machine_product_lines && machine_product_lines.length
          ? machine_product_lines
          : [],
      machine_model_types:
        machine_model_types && machine_model_types.length
          ? machine_model_types
          : [],
      projects: projects && projects.length ? projects : [],
      is_shared,
      description: description ? description : null,
    });
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
