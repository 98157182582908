import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { prepareActions } from "store/helpers";
import { EActions, IManualAction } from "./types";
import { AppState } from "store";
import { enqueueSnackbar } from "notistack";
import i18next from "i18next";
import addMachineManual from "api/handlers/machine/addMachineManual";
import removeMachineManual from "api/handlers/machine/removeMachineManual";
import getManuals from "api/handlers/machine/getManuals";
import { SortingRule } from "react-table";
import patchMachineManual from "api/handlers/machine/patchMachineManual";
export const path = "machineDetail/machineManual";

const pairs = [
  [EActions.fetchManualRequest],
  [EActions.fetchManualSuccess, "manuals"],
  [EActions.fetchManualFail, "error"],
  [EActions.setManualsParams, "manualsParams"],
  [EActions.setReset],
  [EActions.setMore, "more"],
  [EActions.setSort, "sort"],
  [EActions.setMachineSubcategories, "machineSubcategories"],
  [EActions.setCompanyProjects, "companyProjects"],
  [EActions.setMachineManufacturers, "machineManufacturers"],
  [EActions.setMachineProductLines, "machineProductLines"],
  [EActions.setMachineModelTypes, "machineModelTypes"],
  [EActions.setMachines, "machines"],
  [EActions.setQ, "q"],
  [EActions.setFilters, "filters"],

  // Modal
  [EActions.setModalSubcategory, "modalSubcategory"],
  [EActions.setIsShared, "isShared"],
  [EActions.setModalProjects, "modalProjects"],
  [EActions.setModalManufacturer, "modalManufacturer"],
  [EActions.setModalProductLine, "modalProductLine"],
  [EActions.setModalModelType, "modalModelType"],
  [EActions.setIsSpecificMachine, "isSpecificMachine"],
  [EActions.setModalMachine, "modalMachine"],
  [EActions.setModalDescription, "modalDescription"],
];

const actions = prepareActions<IManualAction, EActions>(pairs, path);

export default actions;

export const fetchManuals =
  ({
    page,
    ps,
    orderBy,
    filters,
  }: {
    page?: number;
    ps?: number;
    orderBy?: SortingRule<number>[];
    filters?: {
      machine?: number[] | null;
      machine_subcategory?: number[] | null;
      machine_manufacturer?: number[] | null;
      machine_model_type?: number[] | null;
      machine_product_line?: number[] | null;
      project?: number[] | null;
      q?: string | null;
    } | null;
  }) =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    dispatch(actions[EActions.fetchManualRequest]());

    try {
      const params = {
        page,
        ps,
        orderBy,
        machine: filters && filters.machine ? filters.machine : undefined,
        machine_subcategory:
          filters && filters.machine_subcategory
            ? filters.machine_subcategory
            : undefined,
        machine_manufacturer:
          filters && filters.machine_manufacturer
            ? filters.machine_manufacturer
            : undefined,
        machine_model_type:
          filters && filters.machine_model_type
            ? filters.machine_model_type
            : undefined,
        machine_product_line:
          filters && filters.machine_product_line
            ? filters.machine_product_line
            : undefined,
        project: filters && filters.project ? filters.project : undefined,
        q: filters && filters.q ? filters.q : undefined,
      };
      const manuals = await getManuals(params);

      dispatch(actions[EActions.setManualsParams](params));

      dispatch(actions[EActions.fetchManualSuccess](manuals));
    } catch (error: any) {
      dispatch(actions[EActions.fetchManualFail](error));
    }
  };

export const refetchManualsSilent =
  () =>
  async (
    dispatch: ThunkDispatch<AppState, void, Action> | any,
    getState: any
  ) => {
    try {
      const {
        page,
        ps,
        orderBy,
        machine,
        machine_subcategory,
        machine_manufacturer,
        machine_model_type,
        machine_product_line,
        project,
        q,
      } = getState().manuals.manualsParams;
      const manuals = await getManuals({
        machine,
        page,
        ps,
        orderBy,
        machine_subcategory,
        machine_manufacturer,
        machine_model_type,
        machine_product_line,
        project,
        q,
      });
      dispatch(actions[EActions.fetchManualSuccess](manuals));
    } catch (error) {
      dispatch(actions[EActions.fetchManualFail](error));
    }
  };

export const createManual =
  ({
    machine,
    name,
    data,
    machine_subcategory,
    machine_manufacturer,
    machine_product_lines,
    machine_model_types,
    projects,
    is_shared,
    description,
  }: {
    machine?: number;
    name: string;
    data: string;
    machine_subcategory?: number | null;
    machine_manufacturer?: number | null;
    machine_product_lines?: number[] | null;
    machine_model_types?: number[] | null;
    projects?: number[] | null;
    is_shared?: boolean;
    description?: string | null;
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    enqueueSnackbar(
      i18next.t("machine.detail.manualAttachment.createInProgress")
    );
    try {
      await addMachineManual({
        machine,
        name,
        data,
        machine_subcategory,
        machine_manufacturer,
        machine_product_lines,
        machine_model_types,
        projects,
        is_shared,
        description,
      });
      dispatch(refetchManualsSilent());

      enqueueSnackbar(
        i18next.t("machine.detail.manualAttachment.createSuccess")
      );
    } catch (err: any) {
      if (err?.message === "invalid_attachment_parameter") {
        enqueueSnackbar(
          i18next.t("machine.detail.manualAttachment.invalidFile")
        );
      } else {
        enqueueSnackbar(
          i18next.t("machine.detail.manualAttachment.createError")
        );
      }
    }
  };

export const deleteManual =
  (id: number) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await removeMachineManual(id);
      dispatch(refetchManualsSilent());

      enqueueSnackbar(
        i18next.t("machine.detail.manualAttachment.deleteSuccess")
      );
    } catch (err: any) {
      enqueueSnackbar(i18next.t("machine.detail.manualAttachment.deleteError"));
    }
  };

export const updateManual =
  ({
    id,
    machine,
    machine_subcategory,
    machine_manufacturer,
    machine_product_lines,
    machine_model_types,
    projects,
    is_shared,
    description,
  }: {
    id: number;
    machine?: number;
    machine_subcategory?: number | null;
    machine_manufacturer?: number | null;
    machine_product_lines?: number[] | null;
    machine_model_types?: number[] | null;
    projects?: number[] | null;
    is_shared?: boolean;
    description?: string | null;
  }) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any) => {
    try {
      await patchMachineManual({
        id,
        machine,
        machine_subcategory,
        machine_manufacturer,
        machine_product_lines,
        machine_model_types,
        projects,
        is_shared,
        description,
      });
      dispatch(refetchManualsSilent());

      enqueueSnackbar(i18next.t("machineManual.update.success"));
    } catch (err: any) {
      enqueueSnackbar(i18next.t("machineManual.update.fail"));
    }
  };
