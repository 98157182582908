import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box, Grid, useTheme } from "@mui/material";
import SelectField from "components/selectField";
import { useAsyncReducer } from "store/selectTemplate";
import getMachines from "api/handlers/machine/getMachines";
import CategoriesAndSubcategoriesFilter from "./filters/categoriesAndSubcategories";
import CompaniesAndProjectsFilter from "./filters/companiesAndProjects";
import actions from "store/manuals/actions";
import { useSelector } from "react-redux";
import { AppState } from "store";
import Button from "components/button";
import Search from "components/search";
import MachineManufacturer from "./filters/machineManufacturer";
import MachineProductLine from "./filters/machineProductLine";
import MachineModelType from "./filters/machineModelType";
import Machine from "./filters/machine";

const Filter = () => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const {
    machineManufacturers,
    machineProductLines,
    machineModelTypes,
    machines,
    machineSubcategories,
    companyProjects,
    q,
  } = useSelector((state: AppState) => state.manuals);

  const onSearch = useCallback(() => {
    dispatch(
      actions.setFilters({
        machine: machines,
        machine_subcategory: machineSubcategories,
        machine_manufacturer: machineManufacturers,
        machine_model_type: machineModelTypes,
        machine_product_line: machineProductLines,
        project: companyProjects,
        q,
      })
    );
  }, [
    dispatch,
    machines,
    machineSubcategories,
    machineManufacturers,
    machineModelTypes,
    machineProductLines,
    companyProjects,
    q,
  ]);

  const onQSearch = useCallback(
    (event: any) => {
      dispatch(actions.setQ(event));
    },
    [dispatch]
  );

  const onClearSearch = useCallback(() => {
    dispatch(actions.setMachineManufacturers(null));
    dispatch(actions.setMachineProductLines(null));
    dispatch(actions.setMachineModelTypes(null));
    dispatch(actions.setMachines(null));
    dispatch(actions.setMachineSubcategories(null));
    dispatch(actions.setCompanyProjects(null));
    dispatch(actions.setQ(null));
    dispatch(actions.setFilters(null));
  }, [dispatch]);

  return (
    <Box pb={2} display="flex" gap={1} flexWrap="wrap">
      <Grid paddingBottom={1} container spacing={1}>
        <Grid item xs={12} sm={3} lg={2}>
          <CategoriesAndSubcategoriesFilter />
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <CompaniesAndProjectsFilter />
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <MachineManufacturer />
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <MachineProductLine />
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <MachineModelType />
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <Machine />
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <Search
            value={q as string}
            onSearch={onQSearch}
            placeholder={t("search.searchManuals")}
            isAutoSearch={true}
            hideIcon={true}
            data-testid="search-mock"
          />
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" gap={1}>
        <Button
          color="primary"
          onClick={onSearch}
          data-testid="button-manual.filter.searchButton"
        >
          {t("manual.filter.searchButton")}
        </Button>

        <Button
          onClick={onClearSearch}
          variant="contained"
          data-testid="button-filter.clear"
        >
          {t("filter.clear")}
        </Button>
      </Box>
    </Box>
  );
};

export default Filter;
