import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import { useAsyncReducer } from "store/selectTemplate";
import actions from "store/manuals/actions";
import SelectField from "components/selectField";
import getMachineProductLines from "api/handlers/machine/getMachineProductLines";

const MachineProductLine = ({ modalSelect = false }: any) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();

  const {
    machineManufacturers,
    modalManufacturer,
    modalProductLine,
    machineProductLines,
    modalSubcategory,
    machineSubcategories,
  } = useSelector((state: AppState) => state.manuals);

  const { data, loading } = useAsyncReducer(
    "machineProductLineSelect",
    getMachineProductLines,
    {
      manufacturer: modalSelect ? modalManufacturer : machineManufacturers,
      subcategory: modalSelect ? modalSubcategory : machineSubcategories,
    }
  );

  const productLines = useMemo(() => {
    let options: any = [];
    if (data) {
      options = data.results.map((item: any) => ({
        text: item.name,
        value: item.id,
      }));
    }
    return options;
  }, [data]);

  const onChange = useCallback(
    (values: number[] | string[]) => {
      if (modalSelect) {
        dispatch(actions.setModalProductLine(values.length ? values : null));
      } else {
        dispatch(actions.setMachineProductLines(values.length ? values : null));
      }
    },
    [dispatch]
  );

  return (
    <>
      <SelectField
        searchPlaceholder={t(
          modalSelect
            ? "manualModal.manualHierarchy.machineProductLine.searchPlaceholder"
            : "search.searchMachineProductLine"
        )}
        initialValue={
          modalSelect
            ? modalProductLine || undefined
            : machineProductLines || undefined
        }
        loading={!data || loading}
        disabled={false}
        name="testMachineProductLineSelect"
        label={
          modalSelect
            ? "manualModal.manualHierarchy.machineProductLine.label"
            : t("manual.filter.machineProductLine")
        }
        id="testMachineProductLineSelect"
        options={productLines}
        onChangeSubmit={onChange}
        data-testid="select-field-machineProductLineFilter"
      />
    </>
  );
};

export default MachineProductLine;
