import React, { useState, ReactNode } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import Divider from "components/typography/heading/divider";
import FormHelperText from "@mui/material/FormHelperText";
import _debounce from "lodash/debounce";
import { debounceTime } from "settings";

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      width: "100%",
    },
    groupHeader: {
      marginLeft: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    searchWrapper: {
      boxSizing: "border-box",
      padding: theme.spacing(1.5),
      borderBottom: `${theme.spacing(0.125)} solid ${
        theme.custom.palette.shade
      }`,
      marginBottom: theme.spacing(1.5),
      position: "relative",
    },
    search: {
      fontSize: theme.custom.typography.fontSize[14],
      height: theme.spacing(0.125),
      "&::placeholder": {
        color: theme.custom.palette.data,
        opacity: 1,
      },
    },
    listItem: {
      paddingLeft: theme.spacing(1.5),
    },
    popover: {
      maxHeight: "58vh",
    },
  };
});

export type TOptionsGroup = {
  group: string;
  items: { name: string; id: number }[];
}[];

interface Props extends Omit<FormControlProps, "onChange"> {
  value: string | string[];
  defaultValue?: string;
  onChange?: (
    event: SelectChangeEvent<string | string[]>,
    child: ReactNode
  ) => void;
  search?: string;
  onSearch?: (value: string) => void;
  searchPlaceholder?: string;
  id: string;
  name: string;
  options: TOptionsGroup;
  label: string;
  helperText?: string;
  disabled?: boolean;
}

const SelectGroupSearch = (props: Props) => {
  const { t } = useTranslation();
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const {
    value,
    onChange,
    search,
    onSearch,
    searchPlaceholder,
    id,
    name,
    options,
    label,
    defaultValue,
    helperText,
    disabled = false,
    ...rest
  } = props;

  const classes = useStyles();

  const [searchPhrase, setSearchPhrase] = useState(search || "");

  const debouncedSetSearch = React.useMemo(
    () =>
      _debounce((input: string) => {
        onSearch && onSearch(searchPhrase);
      }, debounceTime),
    [searchPhrase]
  );

  React.useEffect(() => {
    debouncedSetSearch(searchPhrase);
  }, [searchPhrase]);

  return (
    <FormControl variant="outlined" className={classes.root} {...rest}>
      <InputLabel ref={inputLabel} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        labelId={id}
        label={label}
        value={value ?? defaultValue}
        onChange={onChange}
        inputProps={{
          name,
          id,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PopoverClasses: { root: classes.popover },
        }}
        disabled={disabled}
      >
        <div className={classes.searchWrapper}>
          <TextField
            placeholder={searchPlaceholder || t("search.placeholder")}
            variant="outlined"
            InputProps={{ classes: { input: classes.search } }}
            value={searchPhrase}
            onChange={(e: any) => setSearchPhrase(e.target.value)}
            fullWidth
            onKeyDown={(e: any) => {
              e.stopPropagation();
            }}
            onClick={(e: any) => e.stopPropagation()}
          />
        </div>
        {options.map((option: any) => {
          return [
            <div
              className={classes.groupHeader}
              onClick={(e: any) => e.stopPropagation()}
            >
              <Divider line noMargin key={option?.group}>
                {option?.group}
              </Divider>
            </div>,
            option.items.map((item: any) => (
              <MenuItem
                key={item?.id}
                value={item?.id}
                className={classes.listItem}
              >
                {item?.name}
              </MenuItem>
            )),
          ];
        })}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectGroupSearch;
