import { EActions, TReducer, IManualState } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";
import React from "react";

export const initialState: IManualState = {
  manuals: null,
  isFetching: false,
  error: null,
  manualsParams: {
    page: 0,
    ps: 10,
    orderBy: [],
    machine: null,
    machine_subcategory: null,
    machine_manufacturer: null,
    machine_model_type: null,
    machine_product_line: null,
    project: null,
    q: null,
  },
  more: 0,
  sort: [],
  machineSubcategories: null,
  companyProjects: null,
  machineManufacturers: null,
  machineProductLines: null,
  machineModelTypes: null,
  machines: null,
  q: null,
  filters: null,

  // Modal
  modalSubcategory: null,
  isShared: true,
  modalProjects: null,
  modalManufacturer: null,
  modalProductLine: null,
  modalModelType: null,
  isSpecificMachine: false,
  modalMachine: null,
  modalDescription: null,
};

const reducers: TReducer = {
  [EActions.fetchManualRequest]: () => ({
    isFetching: true,
  }),
  [EActions.fetchManualFail]: ({ payload: { error } }) => ({
    manuals: null,
    isFetching: false,
    error: error,
  }),
  [EActions.fetchManualSuccess]: ({ payload: { manuals } }) => ({
    manuals,
    isFetching: false,
    error: null,
  }),
  [EActions.setManualsParams]: ({ payload: { manualsParams } }) => ({
    manualsParams,
  }),
  [EActions.setReset]: () => ({
    ...initialState,
  }),
  [EActions.setMore]: ({ payload: { more } }) => ({
    more,
  }),
  [EActions.setSort]: ({ payload: { sort } }, state) => ({
    more: 0,
    sort,
    manuals: {
      ...state.manuals,
      results: [],
    },
  }),
  [EActions.setMachineSubcategories]: ({
    payload: { machineSubcategories },
  }) => ({ machineSubcategories }),
  [EActions.setCompanyProjects]: ({ payload: { companyProjects } }) => ({
    companyProjects,
  }),
  [EActions.setMachineManufacturers]: ({
    payload: { machineManufacturers },
  }) => ({ machineManufacturers }),
  [EActions.setMachineProductLines]: ({
    payload: { machineProductLines },
  }) => ({ machineProductLines }),
  [EActions.setMachineModelTypes]: ({ payload: { machineModelTypes } }) => ({
    machineModelTypes,
  }),
  [EActions.setMachines]: ({ payload: { machines } }) => ({ machines }),
  [EActions.setQ]: ({ payload: { q } }) => ({ q }),
  [EActions.setFilters]: ({ payload: { filters } }) => ({ filters }),

  // Modal
  [EActions.setModalSubcategory]: ({ payload: { modalSubcategory } }) => ({
    modalSubcategory,
  }),
  [EActions.setIsShared]: ({ payload: { isShared } }) => ({ isShared }),
  [EActions.setModalProjects]: ({ payload: { modalProjects } }) => ({
    modalProjects,
  }),
  [EActions.setModalManufacturer]: ({ payload: { modalManufacturer } }) => ({
    modalManufacturer,
  }),
  [EActions.setModalProductLine]: ({ payload: { modalProductLine } }) => ({
    modalProductLine,
  }),
  [EActions.setModalModelType]: ({ payload: { modalModelType } }) => ({
    modalModelType,
  }),
  [EActions.setIsSpecificMachine]: ({ payload: { isSpecificMachine } }) => ({
    isSpecificMachine,
  }),
  [EActions.setModalMachine]: ({ payload: { modalMachine } }) => ({
    modalMachine,
  }),
  [EActions.setModalDescription]: ({ payload: { modalDescription } }) => ({
    modalDescription,
  }),
};

export default createReducer<IManualState>(path, initialState, reducers);
