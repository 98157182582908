import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "shared/helpers";
import ScreenHeading from "screens/sharedParts/screenHeading";
import Heading1 from "components/typography/heading/heading1";
import Content from "components/layout/content";
import { useMediaQuery, useTheme } from "@mui/material";
import { Description } from "@mui/icons-material";
import MachineManuals from "components/machine/manuals";
import HorizontalLine from "components/horizontalLine";
import CallToAction from "components/layout/callToAction";
import CreateManuals from "components/machine/manuals/createManuals";
import { EModalTypes } from "store/manuals/types";

const Manuals: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("sm"));

  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => setOpenModal(false);

  useEffect(() => {
    setPageTitle(t, t("menu.manuals"));
  }, [t]);

  return (
    <>
      <ScreenHeading>
        <CallToAction
          onClick={() => {
            setOpenModal(!openModal);
          }}
        >
          {t("createManual")}
        </CallToAction>
        <Heading1
          icon={<Description fontSize="large" />}
          position="absolute"
          top={upMd ? "20px" : "15px"}
          left="4.3rem"
        >
          {t("menu.manuals")}
        </Heading1>
      </ScreenHeading>
      <HorizontalLine />
      <Content clear={true}>
        <MachineManuals />
      </Content>
      <CreateManuals
        open={openModal}
        closeModal={closeModal}
        modelType={EModalTypes.create}
      />
    </>
  );
};

export default Manuals;
