import { SortingRule } from "react-table";
import { IMachineManual } from "types/machine";

export enum EActions {
  fetchManualRequest = "fetchManualRequest",
  fetchManualSuccess = "fetchManualSuccess",
  fetchManualFail = "fetchManualFail",
  setReset = "setReset",
  setManualsParams = "setManualsParams",
  setMore = "setMore",
  setSort = "setSort",
  setMachineSubcategories = "setMachineSubcategories",
  setCompanyProjects = "setCompanyProjects",
  setMachineManufacturers = "setMachineManufacturers",
  setMachineProductLines = "setMachineProductLines",
  setMachineModelTypes = "setMachineModelTypes",
  setMachines = "setMachines",
  setFilters = "setFilters",
  setQ = "setQ",

  setModalSubcategory = "setModalSubcategory",
  setIsShared = "setIsShared",
  setModalProjects = "setModalProjects",
  setModalManufacturer = "setModalManufacturer",
  setModalProductLine = "setModalProductLine",
  setModalModelType = "setModalModelType",
  setIsSpecificMachine = "setIsSpecificMachine",
  setModalMachine = "setModalMachine",
  setModalDescription = "setModalDescription",
}

export enum EModalTypes {
  create = "create",
  edit = "edit",
}

export interface IManualState {
  manuals: IMachineManual | null;
  isFetching: boolean;
  error: Error | null;
  manualsParams: any | null;
  sort: SortingRule<number>[];
  more: number;

  machineSubcategories?: number[] | null;
  companyProjects?: number[] | null;
  machineManufacturers?: number[] | null;
  machineProductLines?: number[] | null;
  machineModelTypes?: number[] | null;
  machines?: number[] | null;
  q?: string | null;

  filters?: {
    machine?: number[] | null;
    machine_subcategory?: number[] | null;
    machine_manufacturer?: number[] | null;
    machine_model_type?: number[] | null;
    machine_product_line?: number[] | null;
    project?: number[] | null;
    q?: string | null;
  } | null;

  modalSubcategory?: number[] | null;
  isShared?: boolean;
  modalProjects?: number[] | null;
  modalManufacturer?: number | null;
  modalProductLine?: number[] | null;
  modalModelType?: number[] | null;
  isSpecificMachine?: boolean | null;
  modalMachine?: number | null;
  modalDescription?: string | null;
}

export interface IManualAction {
  type: EActions;
  payload: IManualState;
}

export type TReducer = {
  [key: string]: (p: IManualAction, s: IManualState) => void;
};
