import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IMuiTheme from "types/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: IMuiTheme) => ({
  ellipsisBox: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    gap: "5px",
    flexDirection: "column",
  },
}));

const CompanyAndProject = ({
  cell: {
    value: { is_shared, companyName, projectName },
  },
}: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.ellipsisBox}>
      {is_shared ? (
        <Box color="red">{t("manual.detail.table.sharedProject")}</Box>
      ) : (
        <>
          <span>
            {companyName.map((company: string) => company).join(", ")}
          </span>
          <span>
            {projectName.map((project: string) => project).join(", ")}
          </span>
        </>
      )}
    </Box>
  );
};

export default CompanyAndProject;
